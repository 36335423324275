.chat-parent {
    display: flex;
    padding: 15px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
}

.get-started-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contacts .sidebar-icon {
    display: none !important;
}

.blocking-list {
    padding: 110px 0;
}

.blocking-list .avatar-img,
.messages-list .avatar-img {
    width: 70px !important;
    height: 70px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid;
}

.message-count {
    width: 20px;
    position: absolute;
    top: -8px;
    /* right: 75px; */
    right: 0px;
    height: 20px;
    font-size: 12px;
    border-radius: 100%;
    background-color: #015a98;
    color: white;
    display: flex;
    justify-content: center;
    align-items: cente
}

.right-end {
    position: absolute;
    bottom: 18px;
    right: 12px;
}

.counter {
    height: 24px;
    font-size: 12px !important;
    line-height: 12;
    background: red;
    border-radius: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datee {
    font-size: 12px !important;
    color: #8f7979;
    font-weight: 600 !important;
    position: absolute;
    bottom: 6px;
    right: 15px;
}

.messages-list strong {
    font-size: 14px;
}

.messages-list span,
.messages-list .text-danger {
    font-size: 13px;
    font-weight: normal;
}

.blocking-lis .avatar-img img,
.messages-list .avatar-img img {
    max-width: 100%;
    height: auto;
    object-fit: fill;
    overflow: hidden;
}

.blocking-list .user-list {
    padding: 40px 0;
}

.blocking-lis .modal-backdrop.show {
    opacity: 0.3 !important;
}

.confirm-btn {
    background-color: #6d98bc !important;
    border: 1px solid #6d98bc;
    font-weight: bold;
}

.unblock .modal-body p,
.alert .modal-body p {
    font-size: 22px;
    line-height: 26px;
    color: grey;
}

.warning-icon {
    color: #f0c30e;
    font-size: 20px;
    margin-right: 6px;
}

.alert-msg {
    font-size: 22px;
    color: #4f4242;
    line-height: 32px;
    font-weight: bold;
}

.alert .modal-content {
    width: 500px !important;
    min-height: 250px;
}

.reason h2 {
    font-size: 22px;
    font-weight: bold;
}

.ad-img {
    width: 120px !important;
    height: 120px;
    overflow: hidden;
    border: 1px solid #110b0b42;
    padding: 4px;
    border-radius: 8px;
}

/* .messages-list tbody tr:hove */
.ad-img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    overflow: hidden;
}

.messages-list table {
    border-collapse: separate;
    border-spacing: 0;
}

.tag {
    top: 0;
    right: 0;
    position: absolute;
    border-top: 70px solid #32557f;
    border-left: 80px solid transparent;
}

.tag-content {
    position: absolute;
    top: -60px;
    right: 7px;
    color: #fff;
}

.messages-list .card {
    padding: 15px;
    border-radius: 12px;
    transition: all ease-in-out 0.3s;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
}

.messages-list .card:hover {
    box-shadow: 0 7px 8px rgb(0 0 0 / 30%);
    background-color: transparent;
}

.phones-list-table .card {
    padding-bottom: 36px;
}

/* .card{padding-bottom: 36px;} */
.ribbon {
    width: 125px;
    height: 125px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;

}

.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    /* padding: 15px 0; */
    /* padding: 8px 0; */
    padding: 7px 0 5px;
    background-color: red;
    /* background-color: #3498db; */
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 700 14px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
}

/* top right*/
.ribbon-top-right {
    top: -1px;
    right: -1px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}

.ribbon-top-right::before {
    top: 0;
    left: 0;
}

.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}

.ribbon-top-right span {
    /* left: -25px;
    top: 30px; */
    left: -10px;
    top: 10px;
    transform: rotate(45deg);
}



.messages-list {
    padding: 70px 0;
}

.msg-icon {
    font-size: 45px;
}

.messages-list tbody tr:nth-child(2) td:first-child,
.messages-list tbody tr:nth-child(2) td:last-child {
    border: none;
    background-color: #fff;
}

.b-none {
    border: none;
    background-color: #fff;
}


/* Message List */
.message-list {
    padding: 100px 0 0 0;
}

.message-list .add-info-block {
    max-width: 880px;
    position: relative;
}

.message-list .add-owner-block {
    width: calc(100% - 880px);
    padding: 0 20px;
}

.message-list .image-holder img {
    width: 100%;
    height: 100%;
}

.message-list .image-holder {
    width: 135px !important;
    border-radius: 10px;
    overflow: hidden;
    height: 146px !important;
}

.message-list .add-info-block::after {
    position: absolute;
    content: '';
    right: 0%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid;
}

.message-list .card {
    padding: 5px 15px;
    border-radius: 12px;
    transition: all ease-in-out 0.3s;
    margin-bottom: 15px;
    /* cursor: pointer; */
}

.message-list .card:hover {
    box-shadow: 0 7px 8px rgb(0 0 0 / 30%);
    background-color: transparent;
}

.message-list .avatar-holder {
    width: 80px;
    height: 80px;
    border: 1px solid black;
    overflow: hidden;
    border-radius: 100%;
}

.message-list .message-counter {
    color: #fff;
    width: 32px;
    height: 32px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center !important;
    line-height: 12;
    right: 6px;
    position: absolute;
    bottom: 1px;
}

.message-list .datee {
    font-size: 12px;
}

@media (min-width: 992px) {


    .unblock .modal-lg,
    .modal-xl {
        max-width: 450px;
    }

    .alert .modal-lg,
    .modal-xl {
        max-width: 500px;
    }

    .reason .modal-lg,
    .modal-xl {
        max-width: 500px;
    }

}

/* @media only screen and (max-width:1300px) { .message-count{right: 62px;}} */
@media only screen and (max-width:997px) {
    .avatar-holder {
        margin-bottom: 10px;
    }

    .contacts {
        left: -315px;
        position: absolute;
        top: 145px;
        bottom: 0;
        transition: all .2s ease-in-out;
        z-index: 4;
        top: 0;
    }

    /* .message-count{right: 20px;} */

    .contacts .sidebar-icon {
        display: block !important;
    }

    .contacts .sidebar-icon {
        position: absolute;
        top: 0;
        left: 100%;
        background: #015a98;
        padding: 15px 15px;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        z-index: 2;
        color: #fff;
    }
}

@media (max-width:1200px) {
    .right-end {
        left: 126px;
        position: inherit;
        bottom: 0%;
    }
}

@media (max-width:1024px) {
    .right-end {
        left: 0%;
    }
}

@media (max-width:768px) {
    .right-end {
        right: 0;
    }
}

@media (max-width:340px) {
    .right-end {
        left: 0px;
    }

    .datee {
        bottom: 43px;
        left: 15px;
    }
}