* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.chatbox {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    overflow: auto;
    padding: 0 0 122px;
}

.msg-container-header {
    min-height: 60px;
    background: #015a98;
    border-left: 1px solid white;
}

.cursor-pointer {
    cursor: pointer;
}

.msg-container-header .avatar-img {
    width: 55px !important;
    height: 55px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid;
}

.msg-container-header .avatar-img img {
    max-width: 100%;
    height: auto;
    object-fit: fill;
    overflow: hidden;
}

.avatar-img-msg {
    width: 80px !important;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid;
    margin: 0 15px 0 0;
}

.avatar-img-msg img {
    max-width: 100%;
    height: auto;
    object-fit: fill;
    overflow: hidden;
}

.message-content-block {
    width: calc(100% - 95px);
}

.chatbox-fw {
    width: 100%;
    border: 1px solid #002946;
    position: relative;
}

.typing-box {
    display: flex;
    width: 100%;
    padding: 0 10px;
}

.typing-box .message-input {
    height: 50px;
    resize: none !important;
    border-radius: 4px;
    border: 1px solid #002946;
}

/**Scroll Bar Styles **/
.typing-box .message-input {
    scrollbar-width: thin;
    scrollbar-color: #025a99 #ccc;
}

/* Works on Chrome/Edge/Safari */
.typing-box .message-input::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

.typing-box .message-input::-webkit-scrollbar-track {
    background: #daf0ff;
    border: none;
    border-radius: 4px !important;
}

.typing-box .message-input::-webkit-scrollbar-thumb {
    background-color: #025a99;
    border: none;
    border-radius: 4px !important;
}

.typing-box .message-input:focus {
    outline: none;
}

.typing-box .btn-send {
    width: 160px;
    height: 49px;
    background-color: #015a98;
    /* width: 20%; */
    cursor: pointer;
    color: #fff;
    border: 1px solid #015a98;
    background: #015a98;
    padding: 10px 25px;
    transition: all ease-in-out 0.2s;
    border-radius: 7px;
}

.typing-box .btn-send:hover {
    border-color: #015a98;
    background: transparent;
    color: #015a98;
    border-radius: 27px;
}


.msg-content {
    display: block;
    border: 1px solid #000;
    padding: 10px;
    border-radius: 10px;
    margin: 0 0 15px;

}

.msg-content p {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
}

.msg-content.sender-msg p {
    background: #d4edff;
    border-color: #64bcf9;
    border-radius: 7px 7px 0 7px;
    word-break: break-word;
}

.msg-content.message-read {
    border-color: #99cbee;
    background: #d9eaf7;
}

.chatbox .btn-blue-grad {
    min-width: 200px;
}

.sender-msg {
    text-align: right;
    margin-left: 50%;
}

.sender-msg p {
    background-color: #d2d2d2;
    text-align: left;
}

.msg-time {
    font-size: 12px;
}

.msg-time .message-status-icon {
    color: #015a98;
    font-size: 16px;
}

.sender-msg .seen-status {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 2.5rem;
    color: #cacaca;
    line-height: 0;
}

.sender-msg .color-green {
    color: #3c7900;
}

.icon-check-mark {
    position: relative;
}

.direction {
    direction: rtl;
}

.icon-check-mark:before,
.icon-check-mark:after {
    width: 4px;
    height: 11px;
    content: "";
    border: solid #ccc;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
    right: 0px;
    display: none;
}

.icon-check-mark:after {
    top: 26px;
}

.icon-check-mark.sent:after {
    display: block;
}

.icon-check-mark.delivered:before,
.icon-check-mark.delivered:after {
    display: block;
}

.icon-check-mark.seen:before,
.icon-check-mark.seen:after {
    display: block;
    border-color: #158ff7;
}

.contacts .sidebar-icon {
    display: none !important;
}

.msg-container-header {
    border-radius: 12px;
}

.add-info-block .image-holder {
    width: 126px;
    border-radius: 10px;
    overflow: hidden;
    height: 126px;
}

.add-owner-block .image-holder {
    width: 100px;
    border-radius: 10px;
    overflow: hidden;
}

.image-holder-reciever {
    width: 40px;
    margin-left: 20px;
    height: 40px;
    border-radius: 100%;
    overflow: hidden;
}

.add-info-block {
    position: relative;
    max-width: 980px;
}

.add-info-block::after {
    position: absolute;
    content: '';
    right: 0%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid;
}

.add-owner-block {
    width: calc(100% - 980px);
    padding: 0 8px;
}

/* .add-owner-block .image-holder{width:150px;} */
.add-owner-block .owner-name {
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(1, 90, 152, 0.8);
    padding: 5px;
    font-size: 14px;
}

.add-info-block .details>div {
    width: 33.333%;
    padding: 0 15px 0 0;
    margin: 0 0 15px;
}

.add-info-block .details.second-list>div {
    margin: 0;
}

.add-info-block .details.second-list {
    padding: 0 10px 0 0;
}

.add-owner-block .btn-white-outline:focus {
    border: 1px solid #fff;
    border-radius: 7px;
}

.add-owner-block .btn-white-outline {
    color: #fff;
    padding: 10px 15px;
    border: 1px solid #fff;
    border-radius: 7px;
    transition: all ease-in-out 0.3s;
    font-size: 14px;
    padding: 5px 10px;
    background-color: transparent;
}

.add-owner-block .btn-white-outline:hover {
    background: #fff;
    color: #015a98;
}

.add-owner-block .btn-white-outline:focus-within {
    background: #fff;
    color: #015a98;
    border: 1px solid #fff;
    border-radius: 7px;
}

.add-owner-block .btn-white-outline {
    color: #fff;
    padding: 10px 15px;
    transition: all ease-in-out 0.3s;
    font-size: 14px;
    padding: 5px 10px;
    background-color: transparent;
}

.add-owner-block .btn-white-outline:hover {
    background: #fff;
    color: #015a98;
}

.add-info-block .btn-white-outline,
.add-info-block .btn-white-outline:focus {
    border: 1px solid #fff;
    border-radius: 7px;
}

.add-owner-block .btn-white-outline {
    color: #fff;
    padding: 10px 15px;
    transition: all ease-in-out 0.3s;
    font-size: 14px;
    padding: 5px 10px;
    background-color: transparent;
}

.add-owner-block .btn-white-outline:hover {
    background: #fff;
    color: #015a98;
}

.add-info-block .btn-white-outline {
    color: #fff;
    padding: 10px 15px;
    transition: all ease-in-out 0.3s;
    font-size: 14px;
    padding: 5px 10px;
    background-color: transparent;
}

.add-info-block .btn-white-outline:hover {
    background: #fff;
    color: #015a98;
}

.chat-warning-message.alert,
.chat-info-message.alert {
    border-radius: 0;
}

.type-message-block {
    border: 1px solid #015a98;
    border-radius: 10px;
    padding: 7px;
    width: 100%;
    background: #fff;
}

.type-message-block .image-holder {
    width: 90px;
    border: 1px solid #015a98;
    border-radius: 10px;
    overflow: hidden;
}

.type-message-block textarea.form-control {
    resize: none;
    height: 90px;
    font-size: 14px;
    line-height: 18px;
}

.type-message-block .chatbox-bottom-btn,
.type-message-block .chatbox-bottom-btn:focus {
    border: 1px solid #015a98;
    background: #015a98;
    color: #fff;
    min-width: 130px;
    padding: 10px 15px;
    border-radius: 4px;
    transition: all ease-in-out 0.3s
}

.type-message-block .chatbox-bottom-btn:hover {
    color: #015a98;
    border-color: #015a98;
    background: #fff;
}

.type-message-block .date-time {
    font-size: 14px;
}

@media only screen and (max-width:1199px) {
    .add-info-block h2 {
        font-size: 24px;
        line-height: 28px;
    }
 .navbar-collapse {border-top: none !important;}

    .add-owner-block {
        width: 100%;
    }

    .add-owner-block span {
        text-align: left !important;
    }

    .image-holder-reciever {
        margin-left: 0% !important;
        margin-top: 10px;
    }
}

@media only screen and (max-width:997px) {


    .contacts {
        left: -315px;
        position: absolute;
        top: 145px;
        bottom: 0;
        transition: all .2s ease-in-out;
        z-index: 4;
        top: 0;
    }

    .contacts .sidebar-icon {
        display: block !important;
    }

    .contacts .sidebar-icon {
        position: absolute;
        top: 0;
        left: 100%;
        background: #015a98;
        padding: 15px 15px;
        cursor: pointer;
        border-radius: 0 4px 4px 0;
        z-index: 2;
        color: #fff;
    }

    .chatbox {
        width: 100%;
    }
}

@media only screen and (max-width:991px) {
    .add-info-block .details>div {
        width: 50%;
    }
}

@media only screen and (max-width:767px) {
    .add-info-block h2 {
        font-size: 22px;
        line-height: 25px;
    }

    .add-info-block .details>div {
        width: 100%;
    }

    .avatar-img-msg {
        width: 40px !important;
        height: 40px;
    }

    .message-content-block {
        width: calc(100% - 55px);
    }

    .type-message-block .image-holder {
        width: 40px;
    }

    .add-info-block .image-holder,
    .add-owner-block .image-holder {
        width: 70px;
    }

    .add-owner-block .owner-name {
        inset: auto;
    }

    .type-message-outer {
        width: 100%;
    }

    .chat-parent .msg-container {
        padding: 0 0 115px;
    }
}

@media only screen and (max-width:576px) {
    .typing-box .btn-send {
        margin-top: 8px;
        margin-bottom: 13px;
        height: auto;
        width: auto;
        align-self: flex-end;
    }
}