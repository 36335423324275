/* .d-none{display: none !important;} */

.normal-code-modal .modal-content{border:none;color:#fdf056;}
.normal-code-modal .modal-body{/*background:url('../../../assets/images/bg-01.jpg');*/background-size:cover !important;background-position:center center !important;}
/* .normal-code-modal .modal-content .modal-body{height:285px;} */
.normal-code-modal .modal-header{padding:0;border:none;position: relative;}
.normal-code-modal .modal-header .close{color:#fff;opacity: 1;position: absolute;top:7px;right: 10px;z-index:1;}
.normal-code-modal .modal-header .close.close:not(:disabled):not(.disabled):hover{opacity: 1;}
.normal-code-modal .normal-text-block,
.normal-code-modal .normal-code-block{font-family: 'Heebo';}
.normal-code-modal .normal-text-block{padding:0 45px;}
.normal-code-modal .normal-text-block .counter{font-size:150px;line-height: 130px;}
.normal-code-modal .normal-text-block .normal-text{font-size:45px;line-height:49px;font-weight:300;}
.normal-code-modal .normal-code-block .text{font-size:25px;line-height:29px;}
.normal-code-modal .normal-code-block .normal-code{border:2px solid #fdf056;border-radius:15px;padding:25px 15px;font-size:45px;line-height: 45px;}
.normal-code-modal .normal-des{font-size: 16px;line-height: 22px;font-weight: 600;color: #636363;padding: 15px;margin:0 0 15px;background: #fff;border-radius: 10px;box-shadow: 0 0 7px rgba(0,0,0,0.5);}
.normal-code-modal .normal-des > p{margin-bottom:0;}
.normal-code-modal .btn-blue-outline{padding:12px 30px;}
.norm-banner{background-size: cover !important;background-position: center center !important;}