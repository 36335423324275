
.small-loader{position: relative;margin-left:8px;width: 19px;height: 19px;border-radius: 50%;display: inline-block;vertical-align: middle;
border: 1px solid #fff;-webkit-animation: ball-turn 1s linear infinite;animation: ball-turn 1s linear infinite;}
.small-loader:before{content: "";position: absolute;width: 5px;height: 5px;background: #fff;border-radius: 50%;bottom: 0;right: 12px;}
@-webkit-keyframes ball-turn {
    0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes ball-turn {
    0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
/* Special Site Styles */
.special-site .small-loader{border-color:#750285;}
.special-site .small-loader:before{background: #750285;}

.small-loader.white-loader,
.special-site .small-loader.white-loader{border-color:#fff;}
.small-loader.white-loader:before,
.special-site .small-loader.white-loader:before{background: #fff;}