.main-header{box-shadow:0 0 14px rgba(0,0,0,0.2);position: relative;}
.main-header .header-top{padding:10px 0 12px;background:#015a98;}
.main-header .contact-list{font-size:14px;line-height:17px;}
.main-header .contact-list li{margin: 0 34px 0 0;}
.main-header .contact-list a{color:#fff;}
.main-header .contact-list li svg{width:18px;height:auto;margin:0 12px 0 0;fill:#fff;}
.main-header .logo{width:130px;height:auto;padding:6px 0 0;}
.main-header .logo .site-special-logo{display:none;}
.main-header .header-bottom{padding:5px 0;}
.main-header .navbar{font-size:16px;line-height:19px;font-weight:bold;padding:0;}
.main-header .navbar li{padding:0;}
.navbar-collapse{display:flex;justify-content: flex-start;align-items:center;flex-direction: row-reverse;}
.main-header .navbar .navbar-nav li:last-child{padding-right:0;}
.main-header .navbar-expand-lg .navbar-nav a{padding:0;color:#222222;margin:0 18px;position: relative; text-transform: capitalize;}
.main-header .navbar-light .navbar-nav .active>a,
.main-header .navbar-light .navbar-nav a.active,
.main-header .navbar-light .navbar-nav a.show,
.main-header .navbar-light .navbar-nav .show>a,
.main-header .navbar-light .navbar-nav a:focus,
.main-header .navbar-light .navbar-nav a:hover{color:#015a98;text-decoration: none;}
.main-header .navbar-expand-lg .navbar-nav a:after{width:0;height:3px;content:"";position: absolute; left: 50%;bottom:-2px;background:#015a98;transform: translateX(-50%);-o-transform: translateX(-50%);-ms-transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);}
.main-header .navbar-light .navbar-nav .active>a:after,
.main-header .navbar-light .navbar-nav a.active:after,
.main-header .navbar-light .navbar-nav a.show:after,
.main-header .navbar-light .navbar-nav .show>a:after,
.main-header .navbar-light .navbar-nav a:focus:after,
.main-header .navbar-light .navbar-nav a:hover:after{width:100%;}
.main-header .user-icon-holder{min-width:150px;}
.main-header .user-icon-holder img{width: 48px;border: 1px solid #015a99;border-radius: 100%;height: 48px; object-fit: "cover";}
.main-header .user-icon-holder .user-icon{width:27px;margin:0 0 7px;fill:#015a98;}
.main-header .user-icon-holder:hover .user-icon{transform: rotateY(180deg);-o-transform: rotateY(180deg);-ms-transform: rotateY(180deg);-moz-transform: rotateY(180deg);-webkit-transform: rotateY(180deg);}
.main-header .user-icon-holder .user-name{font-size:14px;color:#015a98;}
.main-header .btn-post-ad .icon-plus{width:23px;margin:0 9px 0 0;}
.main-header .chat-counter{width:20px;height:20px;border-radius:100%;background:#f00;font-size:14px;}
.main-header .daemon-text-holder{height:40px;position: absolute;left:0;top:105px;right:0;color:#fff; background-color: #70721f;}
.main-header .daemon-text-holder .container{height:100%;}
.main-header .daemon-text-holder marquee{width:100%;}
.hide {opacity: 0; visibility: hidden}
.navbar-light .navbar-toggler-icon{background-image:url('../../assets/images/menu-basic.svg') !important;}
.navbar-collapse .btn-post-ad{display:flex;justify-content:center;align-items:center;}
#user-dropdown{background:none;padding:0;border:none;}
.user-dropdown > button > span{display:flex;justify-content:center;align-items:center;flex-direction:column;}
.user-dropdown > button:focus,.user-dropdown > button:active,
.user-dropdown.show>.btn-primary.dropdown-toggle:focus,
.user-dropdown .btn-primary:not(:disabled):not(.disabled):active:focus{box-shadow:none;}
.user-dropdown > button:after{display:none;}
.user-dropdown.show > .dropdown-menu{padding:0;border-radius:0;-o-transform:translate(0,0) !important;-ms-transform:translate(0,0) !important;-moz-transform:translate(0,0) !important;-webkit-transform:translate(0,0) !important;transform:translate(0,0) !important;overflow: hidden;animation: rotateMenu 400ms ease-in-out forwards;transform-origin: top center;top:calc(100% + 16px);}
.user-dropdown.show > .dropdown-menu .dropdown-item{background:#015a98;color:#fff;padding:7px 10px;border-bottom:1px solid #347bad}
.user-dropdown.show > .dropdown-menu .dropdown-item:last-child{border-bottom:0;}
.user-dropdown.show > .dropdown-menu .dropdown-item:hover{background:#023355;color:#fff;}
/* .distance-dropdown .dropdown-menu{padding:0 30px 30px;background:rgba(0,0,0,0.5);border-radius:0 0 4px 4px;top:90px !important;border:none;} */

/* Category Bar Styles */
.category-bar{background:#750183;display:none;position: relative;z-index:6;}
.category-bar .container{position: relative;}
.category-bar .category-bar-holder:after{content:"";clear: both;display:block;}
.category-bar .categories-list{/*display:flex;justify-content:flex-start;align-items: center;*/font-size:13px;line-height:17px;}
/* .category-bar .categories-list li{margin:0 0 0 30px;} */
/* .category-bar .categories-list li:first-child{margin-left:0;} */
.category-bar .categories-list a{min-height:71px;color:#fff;position: relative;padding:22px 10px;}
.category-bar .all-category-link .dropdown-toggle:after,
.category-bar .categories-list a:after{content:"";position: absolute;left: 0;bottom: 100%;width: 100%;height: 100%;background-color: #5b0366;z-index: -1;opacity: 0;-webkit-transition: all .2s ease-out;-o-transition: all .2s ease-out;transition: all .2s ease-out;border:solid #fff;border-width:0 0 4px;}
.category-bar .all-category-link .dropdown-toggle:after{height:0;border:none;}
.category-bar .categories-list a:hover:after,
.category-bar .all-category-link .dropdown-toggle:hover:after{opacity: 1;bottom: -1px;}
.category-bar .all-category-link .dropdown-toggle:hover:after{height:100%;border:solid #fff;border-width:0 0 4px;}
.category-bar .categories-list svg{fill:#fff;width:27px;margin-right:10px;}
.category-bar .all-category-link{width:200px;color:#fff;cursor: pointer;float:left;margin:0 25px 0 0;}
.category-bar .all-category-link .dropdown-toggle{width:100%;padding:24px 10px;text-align: left;overflow: hidden;}
.category-bar .all-category-link .dropdown-toggle.btn-primary,
.category-bar .all-category-link .dropdown-toggle:hover,
.category-bar .all-category-link .dropdown-toggle:focus,
.category-bar .all-category-link .dropdown-toggle:active,
.category-bar .all-category-link .dropdown-toggle.btn-primary:not(:disabled):not(.disabled):active,
.category-bar .all-category-link.show > .btn-primary.dropdown-toggle:focus{background:none;box-shadow: none;outline:none}
.category-bar .all-category-link > .dropdown-menu{top:calc(100% - 1px) !important;padding:0;border:none;border-radius:0;}
.category-bar .all-category-link .dropdown-menu .dropdown-item{background:#015a98;color:#fff;padding: 7px 10px;border-bottom: 1px solid #347bad;}
.category-bar .all-category-link .dropdown-menu .dropdown-item:hover{background:#023355;}
.category-bar .all-category-link.show > .dropdown-menu{-o-transform:translate(0,0) !important;-ms-transform:translate(0,0) !important;-moz-transform:translate(0,0) !important;-webkit-transform:translate(0,0) !important;transform:translate(0,0) !important;overflow: hidden;animation: rotateMenu 400ms ease-in-out forwards;transform-origin: top center;}
.category-bar .sub-categries-holder{width:calc(100% - 225px);float:left;padding:0 22px;}
.category-bar .sub-categries-holder .slick-prev{left:-22px;}
.category-bar .sub-categries-holder .slick-next{right:-22px;}
/* .all-category-link:after{content:"";width:1px; position: absolute;top:-5px;right:-15px;bottom:-5px;background:#9601a8;} */
.all-category-link .dropdown-toggle{background:none;border:none;padding:0;}
.all-category-link .all-categories-holder{width:250px;position: absolute;left: 0;top:calc(100% + 22px);background:#750183;z-index:2;font-size:14px;opacity:0;visibility:hidden;height:0;}
.all-category-link .all-categories-holder li{padding:12px 10px;border-bottom:1px dashed #9d06af;cursor: pointer;}
.all-category-link .all-categories-holder li:hover{background:#47024f;}
.category-bar.active .all-categories-holder{opacity:1;visibility:visible;height:auto;}
/* Site Special Styles*/
.special-site .main-header .container,
.special-site .category-bar .container-fluid{max-width:90%;}
.special-site .main-header .header-top{background:#e6e6e6;}
.special-site .main-header .contact-list a{color:#222222;}
.special-site .main-header .contact-list li svg{fill:#222222;}
.special-site .main-header .contact-list li:hover svg{fill:#750183;}
.special-site .main-header .logo .site-basic-logo{display:none;}
.special-site .main-header .logo .site-special-logo{display:block;}
.special-site .main-header .navbar-expand-lg .navbar-nav a:after{background:#750183;}
.special-site .main-header .navbar-light .navbar-nav .active>a,
.special-site .main-header .navbar-light .navbar-nav a.active,
.special-site .main-header .navbar-light .navbar-nav a.show,
.special-site .main-header .navbar-light .navbar-nav .show>a,
.special-site .main-header .navbar-light .navbar-nav a:focus,
.special-site .main-header .navbar-light .navbar-nav a:hover{color:#750183}
.special-site .main-header .user-icon-holder .user-icon{fill:#750183;}
.special-site .main-header .user-icon-holder .user-name{color:#750183;}
.special-site .navbar-light .navbar-toggler-icon{background-image:url('../../assets/images/menu-special.svg') !important;}
.special-site .category-bar{display:block;}
.special-site .user-dropdown.show > .dropdown-menu .dropdown-item,
.special-site .category-bar .all-category-link .dropdown-menu .dropdown-item{background:#750183;border-color:#9601a8;}
.special-site .user-dropdown.show > .dropdown-menu .dropdown-item:hover,
.special-site .category-bar .all-category-link .dropdown-menu .dropdown-item:hover{background:#47024f;color:#fff;}

/* Cookie Styles*/ 
.decline-button {
	 border-radius: 5px;
	 border-color: #015a98;
	 background:#fff;
	 color:#000;
}

.accept-button {
	background: #025a99;
	color:#fff;
	border-radius: 5px;
}
.CookieConsent{
	font-size:13px;
	
}

.banner-coookie {
animation-duration:1s;
   animation-name:slidein-low;
}


@keyframes slidein-low  {
   from {
      top:-49px;
   }
   
   to {
      top:0;
   }
}



.floating-cookie > div{display:flex !important;justify-content: center;}
.floating-cookie{
	max-width: 600px !important;
	position: fixed !important;
	padding:5px 15px 6px;
	box-shadow:0 5px 5px rgba(0,0,0,0.4);
}
.floating-cookie.top-cookie,
.floating-cookie.bottom-cookie{
	left:50% !important;
	-o-transform:translateX(-50%) !important;
	-ms-transform:translateX(-50%) !important;
	-moz-transform:translateX(-50%) !important;
	-webkit-transform:translateX(-50%) !important;
	transform:translateX(-50%) !important;
}
.floating-cookie.top-cookie{top:0 !important;}
.floating-cookie.bottom-cookie{bottom:0 !important;top:auto !important}
.floating-cookie.left-cookie,
.floating-cookie.right-cookie{
	max-width:250px !important;
	top:50% !important;
	-o-transform:translateY(-50%) !important;
	-ms-transform:translateY(-50%) !important;
	-moz-transform:translateY(-50%) !important;
	-webkit-transform:translateY(-50%) !important;
	transform:translateY(-50%) !important;
	padding:9px 15px 10px;
}
.floating-cookie.left-cookie .content-parent,
.floating-cookie.right-cookie .content-parent{flex-direction: column;}
.floating-cookie.left-cookie .btns-holder,
.floating-cookie.right-cookie .btns-holder{padding-top:10px;}
.floating-cookie.left-cookie{left:0 !important;}
.floating-cookie.right-cookie{right:0 !important;left:auto !important;}
.floating-cookie .e-dlg-content{
	padding:0 !important;
	font-size:14px;
}
.floating-cookie .btns-holder{
	display:flex;
	justify-content: center;
}
.floating-cookie .btns-holder .cookie-btn,
.CookieConsent #rcc-confirm-button,
.CookieConsent #rcc-decline-button{padding:4px 10px !important;border-radius:3px !important;transition: all ease-in-out 0.3s;}
.floating-cookie .btns-holder .accept-button,
.floating-cookie .btns-holder .accept-button:focus,
.CookieConsent #rcc-confirm-button,
.CookieConsent #rcc-confirm-button:focus{border:1px solid #025a99 !important;background:#025a99 !important;color:#fff !important;margin:5px !important;}
.floating-cookie .btns-holder .accept-button:hover,
.CookieConsent #rcc-confirm-button:hover{border-radius: 25px !important;}

.floating-cookie .btns-holder .decline-button,
.floating-cookie .btns-holder .decline-button:focus,
.CookieConsent #rcc-decline-button,
.CookieConsent #rcc-decline-button:focus{border:1px solid #025a99 !important;background:#fff !important;color:#025a99 !important;margin:5px !important;}
.floating-cookie .btns-holder .decline-button:hover,
.CookieConsent #rcc-decline-button:hover{border-radius:25px !important;}
.CookieConsent .cookie-text-link, .CookieConsent .cookie-text-link:focus,
.floating-cookie .cookie-text-link,.floating-cookie .cookie-text-link:focus{
	font-size:13px;
	color:#025a99;
	text-decoration: none;
	margin: 0 0 2px;
	outline:none;
}
.CookieConsent .cookie-text-link:hover,
.floating-cookie .cookie-text-link:hover{color:#025a99}
.CookieConsent .cookie-text-link:after,
.floating-cookie .cookie-text-link:after{
	content:"";
	width:0;
	height:2px;
	position: absolute;
	left: 0;
	bottom: 0;
	background:#025a99;
	transition:all ease-in-out 0.3s;
}
.CookieConsent .cookie-text-link:hover:after,
.floating-cookie .cookie-text-link:hover:after{width:100%;}
.CookieConsent .btn-blue-grad,

.floating-cookie .btn-blue-grad{
	font-size: 13px;
    padding: 4px 10px;
    font-weight: normal;
}

.geolocation-alert .alert {
	position: fixed !important;
	z-index: 1;
	/* width: -webkit-fill-available !important; */
}

.allow-location {
	color: #856404;
	background-color: #fff3cd;
	border-radius: 5px;
	border: 1px solid #856404;
	margin-left: 15px;
	height: 27px;
	font-weight: bold;
}

.geolocation {
	margin: 0;
}


/* .e-dialog .e-dlg-content {
	background-color: slategray;
	color: #fff;
	height: 39px;
	width:600px;
	padding:7px !important;
} */

@-moz-keyframes rotateMenu {
	0% {transform: rotateX(-90deg);}
	70% {transform: rotateX(20deg);}
	100% {transform: rotateX(0deg);}
}
@-webkit-keyframes rotateMenu {
	0% {transform: rotateX(-90deg);}
	70% {transform: rotateX(20deg);}
	100% {transform: rotateX(0deg);}
}
@-o-keyframes rotateMenu {
	0% {transform: rotateX(-90deg);}
	70% {transform: rotateX(20deg);}
	100% {transform: rotateX(0deg);}
}
@keyframes rotateMenu {
	0% {transform: rotateX(-90deg);}
	70% {transform: rotateX(20deg);}
	100% {transform: rotateX(0deg);}
}
@media only screen and (max-width:1634px){
	.all-category-link{width:200px;}
}
@media only screen and (max-width:1599px){
	.all-category-link{width:130px;}
}
@media only screen and (max-width:1509px){
	.category-bar .categories-list li{margin-left:10px;}
	.all-category-link .all-categories-holder{top:calc(100% + 1px);}
}
@media only screen and (max-width:1239px){
	.category-bar .categories-list li svg{margin-right:7px;width:20px;}
}
@media only screen and (max-width:1199px){
	.category-bar .category-bar-holder{flex-direction: column;}
	.all-category-link{width:100%;display:block;margin:0 0 15px;}
	.category-bar .categories-list li{margin-right:20px;}
	.category-bar .categories-list li svg{width:27px;}
	/* .category-bar .sub-categries-holder{overflow-x: auto;} */
	.sub-categries-holder .slick-prev{left:-25px;}
	.sub-categries-holder .slick-next{right:-25px;}
}
@media only screen and (max-width:991px){
	.main-header .logo{padding:0;}
	.navbar-toggler{position: absolute;top:9px;right:15px;padding:0 !important;border:none !important;}
	.navbar-toggler:active,.navbar-toggler:focus{outline:none;}
	.navbar{position: static !important;}
	.header-bottom .container{position: relative !important;}
	.btn-post-ad,
	.user-icon-holder{display:none !important;}
	.navbar-collapse{position:absolute;left:15px;top:calc(100% + 16px);right:15px;background:#fff;border-top:1px solid #015a98;z-index:7}
	.navbar-nav,.navbar-nav a{display:block;width:100%;}
	.main-header .navbar-expand-lg .navbar-nav a{padding:10px 10px;margin:0;background:#015a98;color:#fff;}
	.main-header .navbar-expand-lg .navbar-nav a:hover,
	.main-header .navbar-expand-lg .navbar-nav a:active,
	.main-header .navbar-expand-lg .navbar-nav a:focus{color:#015a98;background:#fff;}
	.main-header .navbar-expand-lg .navbar-nav a::after{display:none;}
	.mobile-user-nav,.mobile-user-nav > li > a{color:#fff;}
	.mobile-user-nav > a:hover{color:#fff;}
	.mobile-user-nav li{float:left;margin:0 10px 0 0;position:relative;}
	.mobile-user-nav li:after{width:1px;content:"";position: absolute;top:4px;right:-2px;bottom:4px;background:#fff;}
	.mobile-user-nav li:last-child{margin-right:0;}
	.mobile-user-nav li:last-child:after{display:none;}
	.mobile-user-nav .dropdown-toggle{font-size:14px;line-height:17px;color:#fff;background:none;border:none;padding:0;}
	.mobile-user-nav .dropdown-toggle:after{display:none;}
	.mobile-user-nav .dropdown-toggle:hover,
	.mobile-user-nav .dropdown-toggle:focus,
	.mobile-user-nav .dropdown-toggle:active,
	.mobile-user-nav .btn-primary:not(:disabled):not(.disabled):active,
	.mobile-user-nav .btn-primary:not(:disabled):not(.disabled):active:focus{color:#fff;background:none;outline:none;box-shadow:none;}
	.mobile-user-nav .show > .btn-primary.dropdown-toggle:focus{background:none;outline:none;box-shadow:none;}
	.mobile-user-nav .dropdown-menu{background:#015a98;padding:0;top:100%;}
	.mobile-user-nav .dropdown-menu a{color:#fff;padding:10px 10px;}
	.mobile-user-nav .dropdown-menu a:hover{color:#fff;background:#023355;}
	.category-bar .all-category-link .angle-down{display: block;}
	.category-bar .all-category-link{margin:0 0 10px;}
	.category-bar .all-category-link,
	.category-bar .sub-categries-holder{width:100%;float:none;}
	.category-bar .all-category-link .dropdown-toggle,
	.category-bar .categories-list a{padding:15px 10px;}
	.category-bar.active .all-category-link .angle-down{transform: rotate(180deg);-o-transform: rotate(180deg);-ms-transform: rotate(180deg);-moz-transform: rotate(180deg);-webkit-transform: rotate(180deg);}
	.category-bar .all-category-link > .dropdown-menu{width:100%;}
	.category-bar .all-category-link .dropdown-menu .dropdown-item{padding:7px 10px 7px 25px;}
	/* Special Site Styles */
	.special-site .navbar-collapse{border-top:1px solid #750183;}
	.special-site .main-header .navbar-expand-lg .navbar-nav a{padding:10px 10px;margin:0;background:#750183;}
	.special-site .main-header .navbar-expand-lg .navbar-nav a:hover,
	.special-site .main-header .navbar-expand-lg .navbar-nav a:active,
	.special-site .main-header .navbar-expand-lg .navbar-nav a:focus{color:#750183;background:#fff;}
	.special-site .mobile-user-nav .dropdown-toggle:hover,
	.special-site .mobile-user-nav .dropdown-toggle:focus,
	.special-site .mobile-user-nav .dropdown-toggle:active,
	.special-site .mobile-user-nav .btn-primary:not(:disabled):not(.disabled):active,
	.special-site .mobile-user-nav .btn-primary:not(:disabled):not(.disabled):active:focus{color:#750183;}
	.special-site .mobile-user-nav,.mobile-user-nav > li > a,
	.special-site .mobile-user-nav > a:hover,
	.special-site .mobile-user-nav .dropdown-toggle{color:#000;}
	.special-site .mobile-user-nav li:after{background:#000;}
	.special-site .mobile-user-nav .dropdown-menu{background:#750183;}
	.special-site .mobile-user-nav .dropdown-menu a:hover{background:#47024f;}
}
@media only screen and (max-width:575px){
	.main-header .contact-list li{margin:0 5px 0 0;}
	.main-header .contact-list li span{display:none;}
	.navbar-collapse{left:0;right:0;}
	.sub-categries-holder{padding:0 20px;}
	.special-site .main-header .container,
	.special-site .category-bar .container-fluid{max-width:100%;}
}