
@import "../node_modules//@syncfusion/ej2-react-popups//styles/material.css";

@font-face {font-family: 'Heebo';src: url('assets/fonts/Heebo-ExtraBold.woff2') format('woff2'),url('assets/fonts/Heebo-ExtraBold.woff') format('woff');font-weight: 800;font-style: normal;font-display: swap;}
@font-face {font-family: 'Heebo';src: url('assets/fonts/Heebo-Bold.woff2') format('woff2'),url('assets/fonts/Heebo-Bold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}
@font-face {font-family: 'Heebo';src: url('assets/fonts/Heebo-Regular.woff2') format('woff2'),url('assets/fonts/Heebo-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
@font-face {font-family: 'Heebo';src: url('assets/fonts/Heebo-Medium.woff2') format('woff2'),url('assets/fonts/Heebo-Medium.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}
@font-face {font-family: 'Heebo';src: url('assets/fonts/Heebo-ExtraLight.woff2') format('woff2'),url('assets/fonts/Heebo-ExtraLight.woff') format('woff');font-weight: 200;font-style: normal;font-display: swap;}
@font-face {font-family: 'Bebas Neue';src: url('assets/fonts/BebasNeue-Regular.woff2') format('woff2'),url('assets/fonts/BebasNeue-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
/* Reset Styles */
body{min-width:280px;font-size:16px;line-height:19px;font-family: 'Heebo';}
ul,ol{margin:0;}
a{color:#025a99;}
a:hover{text-decoration: none;}
button:focus{outline:none;}
.btn:focus{box-shadow:none;}
@media (min-width: 1230px){
.container{max-width: 1170px;}
}
/* Custom Classes */
.cur-poi{cursor: pointer;}
.tdu{text-decoration: underline;}
.bg-theme{background:#025a99;}
.color-theme{color:#025a99;}
.bg-grey{background:#f5f5f5;}
.bg-white{background:#fff;}
.text-primary{color:#025a99 !important;}
.w-90{width:90% !important;}
.w-80{width:80% !important;}
.w-70{width:70% !important;}
.w-60{width:60% !important;}
.w-40{width:40% !important;}
.w-30{width:30% !important;}
.w-35{width:35% !important;}
.w-20{width:20% !important;}
.w-10{width:10% !important;}
.pos-rel-z-index{position: relative;z-index:1;}
.badge-primary{background:#025a99;}
.btn-blue-grad,.btn-green-grad,.btn-wish-list,.btn-grey-bordered,.btn-bg-grey{font-size:16px;line-height:19px;font-weight:bold;color:#fff;padding:12px 17px;text-shadow: none;position:relative;background:#fff;border-radius:4px;overflow: hidden;}
.btn-blue-grad{border:1px solid #025a99;background: #025a99;
background: -moz-linear-gradient(top,  #025a99 0%, #025a99 17%, #024f85 66%, #004c7e 77%, #024677 100%);
background: -webkit-linear-gradient(top,  #025a99 0%,#025a99 17%,#024f85 66%,#004c7e 77%,#024677 100%);
background: linear-gradient(to bottom,  #025a99 0%,#025a99 17%,#024f85 66%,#004c7e 77%,#024677 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#025a99', endColorstr='#024677',GradientType=0 );
}
/* .btn-blue-grad span,.btn-green-grad span,.btn-wish-list span,.btn-grey-bordered span,.btn-bg-grey span{position: relative;z-index:2;}
.btn-blue-grad:after,.btn-green-grad:after,.btn-wish-list:after,.btn-grey-bordered:after,.btn-bg-grey:after{content: "";position: absolute;z-index: -1;top: 0;bottom: 0;left: 0;right: 0;-webkit-transform: scaleX(1.1);transform: scaleX(1.1);-webkit-transform-origin: 50%;transform-origin: 50%;-webkit-transition-property: transform;transition-property: transform;-webkit-transition-duration: 0.3s;transition-duration: 0.3s;-webkit-transition-timing-function: ease-out;transition-timing-function: ease-out;z-index: 1;} */
/* .btn-blue-grad:after{background: #025a99;} */
.btn-green-grad{background: #058518;border:1px solid #058518;}
.btn-grey-bordered{background:#fff;}
.btn-bg-grey{color:#000;border:1px solid #f2f2f2;background: #d3d3d3;
background: -moz-linear-gradient(top,  #d3d3d3 0%, #d5d5d5 26%, #e2e2e2 100%);
background: -webkit-linear-gradient(top,  #d3d3d3 0%,#d5d5d5 26%,#e2e2e2 100%);
background: linear-gradient(to bottom,  #d3d3d3 0%,#d5d5d5 26%,#e2e2e2 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3d3d3', endColorstr='#e2e2e2',GradientType=0 );
}
.btn-wish-list{background: #fff;border:1px solid #e94335;text-align: center;}
/* .btn-blue-grad:hover:after,
.btn-green-grad:hover:after,
.btn-wish-list:hover:after,
.btn-grey-bordered:hover:after,.btn-bg-grey:hover:after,.input-file-holder .input-file-btn-holder:hover .btn-bg-grey:after{-webkit-transform: scaleX(0);transform: scaleX(0);} */

.btn-blue-grad:hover,
.btn-bg-grey:hover,.btn-bg-grey:focus,.btn-bg-grey:active,
.btn-grey-bordered:hover,.btn-grey-bordered:focus,.btn-grey-bordered:active,
.btn-blue-outline:hover,.btn-blue-outline:focus,.btn-blue-outline:active{border-radius:27px;outline:none;}

.btn-blue-outline:hover,.btn-blue-outline:focus,.btn-blue-outline:active{background:#fff;color:#025a99;border-color:#025a99;}

.btn-blue-grad:hover,.btn-blue-grad:focus,.btn-blue-grad:active{color:#fff;outline:none;}
.btn-bg-grey:hover,.btn-bg-grey:focus,.btn-bg-grey:active{color:#000;}

.btn-blue-grad svg,.btn-green-grad svg{fill:#fff;}

.btn-blue-grad:hover svg,.btn-blue-grad:focus svg,.btn-blue-grad:active svg{fill:#fff;outline:none;}
.btn-bg-grey:hover svg,.btn-bg-grey:focus svg,.btn-bg-grey:active svg{fill:#000;}

.btn-green-grad:after{background: -moz-linear-gradient(left,  #058518 0%, #058318 100%);background: -webkit-linear-gradient(left,  #058518 0%,#058318 100%);background: linear-gradient(to right,  #058518 0%,#058318 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#058518', endColorstr='#058318',GradientType=1 );}
.btn-wish-list:after{background: #f9f9f9;background: -moz-linear-gradient(top,  #f9f9f9 0%, #ececec 100%);background: -webkit-linear-gradient(top,  #f9f9f9 0%,#ececec 100%);background: linear-gradient(to bottom,  #f9f9f9 0%,#ececec 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#ececec',GradientType=0 );}
.btn-bg-grey:after{background:#f2f2f2;}
/* .btn-green-grad:hover,.btn-green-grad:focus,.btn-green-grad:active{background:#fff;color:#058518;} */
.btn-green-grad:hover svg,.btn-green-grad:focus svg,.btn-green-grad:active svg{fill:#fff;}
.btn-wish-list svg{fill:#e94335;margin-right:0;}
.btn-bg-grey svg{fill:#272727;width:24px;margin-right:7px;}
.btn-grey-bordered{color:#777171;border:1px solid #777171;}
.btn-grey-bordered:hover,.btn-grey-bordered:focus,.btn-grey-bordered:active{color:#025a99;border-color:#025a99}
.btn-bg-grey:hover svg,.btn-bg-grey:focus svg,.btn-bg-grey:active svg{fill:#272727;}
.btn-blue-outline{font-size: 16px;line-height: 19px;font-weight: bold;color: #fff;border:1px solid #025a99;color:#025a99;background:#fff;padding:7px 17px;border-radius:4px;}

.btn-blue-grad.disabled-white{background:#fff;color:#c10000;}
.btn-blue-grad.disabled-white:disabled{opacity: 1;}
.btn-grey-grad,
.btn-grey-grad:focus{background:#d9d9d9;border-color:#a6a6a6;color:#fff;outline:none;box-shadow: none; cursor: not-allowed;}
.btn-grey-grad:hover{border-radius:27px;outline:none;color:#fff;}
.btn-green-grad:hover{border-radius:27px;color:#fff;}
.btn-wish-list:hover{border-radius:27px;}

.btn-white-grad{border-color:#fff;background-color:transparent;border-radius:3px;padding:5px 10px;margin-right: 5px;color:#fff;}
.btn-white-grad:hover,.btn-white-grad:focus{background-color:#fff;color:#025a99;}
/* .add-footer .footer-btns-holder .btn-blue-grad:disabled{background:#ccc;border-color:#ccc;} */

.btn-grey-outline,.btn-grey-outline:focus{border-color:#7e7e7e;color:#c10000;outline:none;box-shadow:none;}
.btn-grey-outline:hover{background:#c10000;color:#fff;border-color:#c10000;border-radius:27px;}

h2,.h2{font-size:30px;line-height:34px;color:#000;font-weight:500;}
h3,.h3{font-size:20px;line-height:23px;color:#000;font-weight:normal;}
/* Common Styles */
.add-contact-btns a svg,.btn-blue-grad,.btn-bg-grey,.btn-grey-bordered,.btn-green-grad,.btn-wish-list,.single-add-holder,.popular-categories .category-holder a,.popular-categories .category-icon,.popular-categories .category-icon svg,.popular-categories .category-name,.all-categories .category-holder a,.all-categories .category-icon,.all-categories .category-icon svg,.all-categories .category-name,.dashboard-items .dashboard-item a,.dashboard-items .dashboard-item > span,.dashboard-items .category-icon,.dashboard-items .category-icon svg,.dashboard-items .category-name,.single-add-holder .footer-btns > li,.single-add-holder .message-dropdown a,.single-add-holder .message-dropdown svg,.footer-bottom .fotoer-nav a,.footer-bottom .footer-nav a:before,.footer-contact-nav li a,.footer-contact-nav li svg,.btn-white-bg,.category-bar .categories-list,.category-bar .all-category-link .angle-down,.main-header .user-icon-holder .user-icon,.profile-pages-nav a,.profile-pages-nav .page-icon svg,.settings-sidebar-holder,.back-page-arrow svg,.view-icons-holder .icon-holder,.view-icons-holder .icon-holder svg,.view-icons-holder .view-name,.detail-form-holder .service-tags-list .icon-holder,.all-category-link .all-categories-holder,.multiple-uploads .image-item,.multiple-uploads .image-item .btn-remove,.multiple-uploads .image-item .btn-edit,.contact-medium-lists li svg,.multiple-pdf-uploads .input-file-holder .input-file-btn,.btn-blue-outline,.btn-grey-grad,.filter-sidebar .filter-widget li a,.filtered-ads .filter-sidebar,.add-content-article .pdfs-holder a,.single-add-holder .footer-btns .fav,.single-add-holder .footer-btns .notfav{-o-transition: all ease-in-out 0.3s !important;-ms-transition: all ease-in-out 0.3s !important;-moz-transition: all ease-in-out 0.3s !important;-webkit-transition: all ease-in-out 0.3s !important;transition: all ease-in-out 0.3s !important;}
/*cubic bezier animation */
.main-header .navbar-expand-lg .navbar-nav .nav-link:after{transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);-o-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);-ms-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);-moz-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);-webkit-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);}
.heading-section{font-size:16px;line-height:20px;color:#a5a5a5;}
.heading-section h2{font-size:30px;line-height:35px;font-weight:bold;margin:0 0 15px;color:#222222;}
.content-section{padding:100px 0;}
.back-page-arrow svg{width:23px;fill:#025a99;margin:0 10px 0 0;}
.back-page-arrow:hover svg{transform: translate(-10px, 0);}
.mini-faq-icon{width:20px;fill:#025a99;}
/* Form Styles */
.form-control{/*padding:22px 20px;*/padding:10px 15px;min-height:46px;border-radius:4px;font-size:14px;line-height:17px;border-color:#dfdfdf !important;color:#000}
.form-control:focus{border-color:#025a99 !important;outline:none !important;box-shadow: none !important;}
.form-control:disabled, .form-control[readonly]{background:#fff;}
.form-control:disabled:focus, .form-control[readonly]:focus{border-color:#dfdfdf !important;}
.form-control::-webkit-input-placeholder,
.form-control[disabled]::-webkit-input-placeholder {color:#000 !important;}
.form-control::-moz-placeholder,
.form-control[dsiabled]::-moz-placeholder {color:#000 !important;opacity:1;}
.form-control::-moz-placeholder,
.form-control[disabled]::-moz-placeholder {color:#000 !important;opacity:1;}
.form-control::-ms-input-placeholder,
.form-control[disabled]::-ms-input-placeholder {color:#000 !important;}
.form-control::-ms-input-placeholder,
.form-control[disabled]::-ms-input-placeholder {color:#000 !important;}
.form-control::placeholder,
.form-control[disabled]::placeholder {color:#000 !important;}
.form-control:focus{color:#025a99;}
.megaad-site-table thead{background:#025a99;color:#fff;white-space: nowrap;}
.megaad-site-table thead th,.megaad-site-table tbody td{border:none;}
.megaad-site-table tbody tr:nth-child(even){background:#f5f5f5;}
.megaad-site-table.bordered-table thead th,.megaad-site-table.bordered-table tbody td{border:1px solid #e6e6e6;}
.custom-select-holder{position: relative;}
.react-select__control,
.react-select__option{cursor: pointer !important;}
.react-select__control,
.react-select__control.react-select__control--menu-is-open{min-height: 46px !important;}
.react-select__menu{z-index:2 !important;}
.react-select__placeholder{color:#222 !important;}
.special-site .btn-blue-outline:hover,.special-site .btn-blue-outline:focus,.special-site .btn-blue-outline:active{background:#fff;color:#760183;border-color:#760183;}
.alert.alert-danger{background:none !important;border-color:#ed9f21 !important;padding:12px 20px 12px 50px !important;position: relative;color:#d88f8f;font-size:14px;}
.alert.alert-danger:before{content:"";width:35px;height:35px;position:absolute;left:5px;top:50%;background:url('../src/assets/images/exclamation-triangle-solid.svg'); background-repeat: no-repeat;z-index: 1;-o-transform: translateY(-50%);-ms-transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);transform: translateY(-50%);}
.alert.alert-danger:after{content:"";width:40px;position: absolute;left: 0;top:0;bottom:0;}

.alert.alert-info{background:none !important;border-color:#025a99 !important;padding:15px 20px 15px 50px !important;position: relative;color:#025a99;font-size:14px;}
.alert.alert-info:before{content:"";width:35px;height:35px;position:absolute;left:5px;top:8px;background:url('../src/assets/images/info-circle.svg'); background-repeat: no-repeat;z-index: 1;}
.alert.alert-info:after{content:"";width:40px;position: absolute;left: 0;top:0;bottom:0;}

.alert.alert-danger.danger-text-blue{color:#025a99;}
.alert.alert-danger.text-danger{color:#dc3545!important;border-color:#dc3545!important;}
.alert.alert-danger.text-danger:before{background:url('../src/assets/images/exclamation-triangle-solid-danger.svg');background-repeat: no-repeat;}

.custom-select-holder.react-select--is-disabled .react-select__control{border-color:#dfdfdf;background-color:transparent}
.custom-select-holder.react-select--is-disabled .react-select__single-value{color:#495057;margin:0;left:50%;top:50%;-o-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);-moz-transform: translate(-50%,-50%);-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);}
.custom-select-holder.react-select--is-disabled .css-b8ldur-Input{width:0;margin:0;}
.custom-select-holder.react-select--is-disabled .react-select__indicators{display:none;}
.megaad-table{font-size:14px;}
.megaad-table thead{background: #025a99;color:#fff;}
.megaad-table tbody tr:nth-child(odd){background:#fff;}
.megaad-table tbody tr:nth-child(even){background:#f1f1f1;}
button.btn-blue-grad:disabled{opacity: 0.65;}
button.btn-blue-grad:disabled:hover{border-radius: 4px;cursor: not-allowed;}
.banner-location-select .react-select__control{height:100%;border:none;}

.react-datepicker{border-color:#025a99;}
.react-datepicker__header{background:#025a99;}
.react-datepicker__current-month,
.react-datepicker-time__header,.react-datepicker-year-header,
.react-datepicker__day-name{color:#fff;}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover{border-right-color:#fff;}
.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover{border-left-color:#fff;}
.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover{opacity: 0.7;}


/* Right Label Checkbox */
.right-label-checkbox {display: block;position: relative;padding-left: 25px;cursor: pointer;font-size: 16px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.right-label-checkbox input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.right-label-checkbox .checkmark{position: absolute;top: 0;left: 0;height: 16px;width: 16px;border:1px solid #025a99;border-radius: 4px;}
.right-label-checkbox .checkmark:after {content: "";position: absolute;display: none;}
.right-label-checkbox input:checked ~ .checkmark:after {display: block;}
.right-label-checkbox .checkmark:after {left: 4px;top: 0px;width: 6px;height: 12px;border: solid #025a99;;border-width: 0 2px 2px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
.right-label-checkbox input:disabled ~ .checkmark {border-color:#7f7f7f;background:#d9d9d9;}
.right-label-checkbox.available-checkbox input:disabled ~ .checkmark {border-color:#025a99;background:#fff;}
/* Right Label Checkbox */
.right-label-checkbox2 .checkmark{border-radius:0;border-width:2px;background:rgba(2,90,153,0.6);}
.right-label-checkbox2 input:checked ~ .checkmark{background:#025a99;}
.right-label-checkbox2 input:checked ~ .checkmark:after{display:none;}

.ck.ck-editor__main>.ck-editor__editable{border-radius:5px;font-size:14px;color:#000;}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){border-color:#dfdfdf !important;}
.ck.ck-editor__main>.ck-editor__editable.ck-focused{border-color:#025a99;box-shadow: none !important;color:#025a99;border-radius: 5px;}
/* Right Label Radio */
.custom_width{width: 40%;}
.custom_2width{width: 50%;}
.right-label-radio {display: block;position: relative;padding-left: 25px;cursor: pointer;font-size: 16px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.right-label-radio input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.right-label-radio .checkmark {position: absolute;top: 0;left: 0;height: 20px;width: 20px;border:1px solid #025a99;border-radius: 50%;}
.right-label-radio input:checked ~ .checkmark {background-color: #fff;}
.right-label-radio .checkmark:after {content: "";position: absolute;display: none;}
.right-label-radio input:checked ~ .checkmark:after {display: block;}
.right-label-radio .checkmark:after {top: 4px;left: 4px;width: 10px;height: 10px;border-radius: 50%;background: #025a99;}
/* Boxed Checkbox */
.boxed-checkbox {min-width: 100px;min-height: 35px;display: block;position: relative;margin-bottom: 12px;cursor: pointer;font-size: 16px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.boxed-checkbox input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.boxed-checkbox .checkmark {position: absolute;top: 0;left: 0;right:0;bottom: 0;}
.boxed-checkbox .checkbox-text{padding:15px 30px;display:inline-block;vertical-align: top;border:1px solid #000;border-radius:4px;color:#000;display: flex;justify-content: center;align-items: center;}
/* .boxed-checkbox input:checked ~ .checkmark {} */
.boxed-checkbox input:checked ~ .checkbox-text{background-color: #025a99;border-color:#025a99;color:#fff;}
.calendar-holder .react-datepicker-wrapper{width:100%;}
.circular-bullets li{padding:0 0 0 15px;margin:0 0 10px;position: relative;}
.circular-bullets li:before{content:"";width:7px;height:7px;border-radius:100%;position:absolute;left:0;top:50%;-o-transform:translateY(-50%);-ms-transform:translateY(-50%);-moz-transform:translateY(-50%);-webkit-transform:translateY(-50%);transform:translateY(-50%);overflow:hidden;background:#025a99;}
.cms-list-points li{padding:0 0 0 15px;position: relative;margin:0 0 5px;}
.cms-list-points li:before{width:7px;height:7px;border-radius:100%;content:"";position: absolute;left:0;top:6px;background:#025a99;}
/* Modal Footer Styles */
.modal-footer{align-items:normal;}
/* Custom Alerts Styles */
.custom-react-alret{background:#025a99;padding:15px;font-size:18px;line-height:22px;color:#fff;display:flex;align-items:center;border-radius:5px;box-shadow:0 0 5px rgba(0,0,0,0.4);}
.custom-react-alret .icon-holder{font-size:30px;color:#fff;}
.custom-react-alret button{background: none;box-shadow: none;color: #fff;margin-left: 10px;border: 1px solid #fff;border-radius: 100%;font-size: 14px;width: 25px;height: 25px;}

/**Alert Styles **/
.react-confirm-alert-body{padding: 30px 15px;font-family: 'Heebo';}
.react-confirm-alert-body > h1{font-size:24px;text-transform: capitalize;}
.react-confirm-alert-body .react-confirm-alert-button-group{justify-content: flex-end;}
.react-confirm-alert-body .react-confirm-alert-button-group > button{transition: all ease-in-out 0.2s;}
.react-confirm-alert-body .react-confirm-alert-button-group > button,
.react-confirm-alert-body .react-confirm-alert-button-group > button:focus{min-width:80px;background:#025a99;border:1px solid #025a99;font-size:14px;line-height: 14px;}
.react-confirm-alert-body .react-confirm-alert-button-group > button:hover{background:transparent !important;border-color:#025a99 !important;color:#025a99 !important;}

.react-confirm-alert-body .react-confirm-alert-button-group > button + button,
.react-confirm-alert-body .react-confirm-alert-button-group > button + button:focus{min-width:80px;background:#fff;border:1px solid #025a99;font-size:14px;line-height: 14px;color:#025a99;}
.react-confirm-alert-body .react-confirm-alert-button-group > button + button:hover{background:#025a99 !important;border-color:#025a99 !important;color:#fff !important;}


/**Scroll Bar Styles **/
* {scrollbar-width: thin;scrollbar-color: #025a99 #ccc;}
/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {width: 8px;height:8px;border:none !important;}
*::-webkit-scrollbar-track {background: #daf0ff;border:none !important;}
*::-webkit-scrollbar-thumb {background-color: #025a99;border:none ;}
/* Range Slider */
.rc-slider-handle{cursor: pointer;}
.rc-slider-track{background:#025a99;}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.rc-slider-handle-click-focused,.rc-slider-handle-click-focused:focus,
.rc-slider-handle,.rc-slider-handle:hover,.rc-slider-handle:active{border-color:#025a99;}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{box-shadow:none;}

/* React Stars */
.react-stars:focus{outline:none;}

/* Special Site Styles */
.special-site a,
.special-site .color-theme,
.special-site .btn-blue-outline,
.special-site .btn-blue-outline span,
.special-site .btn-grey-bordered:hover,
.special-site .color-theme,
.special-site .alert.alert-danger.danger-text-blue{color:#750285;}
.special-site .text-primary{color:#750285 !important;}

.special-site .badge-primary,
.special-site .bg-theme,
.special-site .btn-blue-grad,
.special-site .btn-blue-grad:after,
.special-site .megaad-site-table thead,
.special-site .megaad-table thead,
.special-site .rc-slider-track,
.special-site .right-label-radio .checkmark:after{background: #750285;}

.special-site .btn-blue-grad,
.special-site .btn-grey-bordered:hover,.special-site .btn-grey-bordered:focus,.special-site .btn-grey-bordered:active,
.special-site .btn-blue-outline,
.special-site .right-label-checkbox .checkmark,
.special-site .right-label-checkbox .checkmark:after,
.special-site .right-label-checkbox.available-checkbox input:disabled ~ .checkmark,
.special-site .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging,
.special-site .rc-slider-handle-click-focused,.special-site .rc-slider-handle-click-focused:focus,
.special-site .rc-slider-handle,.special-site .rc-slider-handle:hover,.special-site .rc-slider-handle:active,
.special-site .right-label-radio .checkmark{border-color:#750285;}
.special-site .form-control:focus{border-color:#750285 !important;}

.special-site .btn-blue-grad,
.special-site .btn-blue-grad span{color:#fff;}
.special-site .mini-faq-icon,
.special-site .back-page-arrow svg{fill:#750285;}
.special-site .btn-blue-grad{background: #760183;
background: -moz-linear-gradient(top,  #760183 0%, #6f017c 41%, #6d0179 43%, #6d0179 49%, #6a0177 51%, #5d0068 100%);
background: -webkit-linear-gradient(top,  #760183 0%,#6f017c 41%,#6d0179 43%,#6d0179 49%,#6a0177 51%,#5d0068 100%);
background: linear-gradient(to bottom,  #760183 0%,#6f017c 41%,#6d0179 43%,#6d0179 49%,#6a0177 51%,#5d0068 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#760183', endColorstr='#5d0068',GradientType=0 );}

a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
.blocked-user-warnings{max-width:95%;margin:10px auto;border:2px solid #ffc107;padding:10px;font-size:16px;}
.blocked-user-warnings .icon-holder{font-size:55px;margin:0 15px 0 0;}
.numeric-key-code .text-block{border:1px solid #ed9f21;padding:12px 50px;border-radius:4px;color:#025a99;}
@media (min-width:576px){
	.blocked-user-warnings{max-width:510px}
}
@media (min-width:768px){
	.blocked-user-warnings{max-width:690px}
}
@media (min-width:992px){
	.blocked-user-warnings{max-width:930px}
}
@media (min-width:1200px){
	.blocked-user-warnings{max-width:1110px}
}
@media (min-width:1230px){
	.blocked-user-warnings{max-width:1140px}
}

@media only screen and (max-width:1199px){
	.content-section{padding:80px 0;}
}
@media only screen and (max-width:991px){
	.pos-stat{position: static;}
	.content-section{padding:50px 0;}
	.profile-settings .container{position: relative;}
	.settings-sidebar-holder .settings-sidebar,
	.settings-sidebar-holder{width:44px;position: absolute;left:15px;top:0;z-index:2;overflow: hidden;}
	.settings-sidebar-holder{height:100%;}
	.settings-sidebar-holder:hover,
	.settings-sidebar-holder .settings-sidebar{width:290px;}
	.settings-sidebar-holder .settings-sidebar{left:0;}
	.custom_width{width: 30%;}
	.custom_2width{width: 30%;}

}
@media only screen and (max-width:767px){
	.content-section{padding:30px 0;}
	.heading-section h2,
	h2,.h2{font-size:26px;line-height:29px;}
	h3, .h3{font-size:18px;line-height:21px;}
	.react-confirm-alert-body{width:300px !important;}
}
@media only screen and (max-width:400px){
    .text-notce-model .btn-blue-grad{ padding: 8px 3px;font-size:13px;width:max-content;}
	.react-confirm-alert-body{width:300px !important;}
}