.footer-top{background:#f6f6f6;position:relative;padding:90px 0 50px;}
.footer-top:before{height:3px;content:"";position:absolute;left:0;top:13px;right:0;background:#015a98;}
.footer-tags-block h3{color:#015a98;}
.tags-cloud{overflow: hidden;}
.tags-cloud li{float:left;margin:0 8px 8px 0;font-size:14px;line-height:17px;color:#000;}
.tags-cloud li a{color:#000;padding:8px 20px;border-radius:4px;display:inline-block;vertical-align: top;}
.tags-cloud.cities-tags-cloud li,
.tags-cloud.adds-tags-cloud li{background:#ffe2c7;display:flex;justify-content: center;align-items:center;}
.tags-cloud.cities-tags-cloud li a,
.tags-cloud.adds-tags-cloud li a{position:relative;}
.tags-cloud.cities-tags-cloud li,.tags-cloud.adds-tags-cloud li{position: relative;}
.tags-cloud.cities-tags-cloud li:before,.tags-cloud.cities-tags-cloud li:after,.tags-cloud.adds-tags-cloud li:before,.tags-cloud.adds-tags-cloud li:after{position: absolute;content:"";left: 0;background-color: #fcc38e;}
.tags-cloud.cities-tags-cloud li:before,.tags-cloud.adds-tags-cloud li:before{width: 3px;height: 0;bottom: 0;-webkit-transition: all .1s .3s;-o-transition: all .1s .3s;transition: all .1s .3s;}
.tags-cloud.cities-tags-cloud li:hover:before,.tags-cloud.adds-tags-cloud li:hover:before{height:100%;-webkit-transition: all .1s;-o-transition: all .1s;transition: all .1s;}
.tags-cloud.cities-tags-cloud li:after,.tags-cloud.adds-tags-cloud li:after{width: 0;height: 3px;top: 0;-webkit-transition: all .1s .2s;-o-transition: all .1s .2s;transition: all .1s .2s;}
.tags-cloud.cities-tags-cloud li:hover:after,.tags-cloud.adds-tags-cloud li:hover:after{width: 100%;-webkit-transition: all .1s .1s;-o-transition: all .1s .1s;transition: all .1s .1s;}
.tags-cloud.cities-tags-cloud li a:before,.tags-cloud.cities-tags-cloud li a:after,.tags-cloud.adds-tags-cloud li a:before,.tags-cloud.adds-tags-cloud li a:after{content:"";background-color: #fcc38e;position:absolute;}
.tags-cloud.cities-tags-cloud li a:before,.tags-cloud.adds-tags-cloud li a:before{width: 3px;height: 0;right: -1px;top: 0;-webkit-transition: all .1s .1s;-o-transition: all .1s .1s;transition: all .1s .1s;}
.tags-cloud.cities-tags-cloud li:hover a:before,.tags-cloud.adds-tags-cloud li:hover a:before{height: 100%;-webkit-transition: all .1s .2s;-o-transition: all .1s .2s;transition: all .1s .2s;}
.tags-cloud.cities-tags-cloud li a:after,.tags-cloud.adds-tags-cloud li a:after {width: 0;height: 3px;right: -1px;bottom: -1px;-webkit-transition: all .1s;-o-transition: all .1s;transition: all .1s;position: absolute;}
.tags-cloud.cities-tags-cloud li:hover a:after,.tags-cloud.adds-tags-cloud li:hover a:after {width: 100%;-webkit-transition: all .1s .3s;-o-transition: all .1s .3s;transition: all .1s .3s;}
.tags-cloud.adds-tags-cloud li{background:#dbedff;}
.tags-cloud.adds-tags-cloud li:after,.tags-cloud.adds-tags-cloud li:before,.tags-cloud.adds-tags-cloud li a:after,.tags-cloud.adds-tags-cloud li a:before{background-color:#7fbdfb;}
.footer-bottom{background:#e7e7e7;padding:40px 0 55px;}
.footer-bottom h3{margin:0 0 35px;}
.footer-logo{margin:0 0 35px;}
.footer-logo img{width:130px;}
.footer-logo .site-special-logo{display:none;}
.footer-bottom .footer-about-text{margin:0 0 40px;font-size:14px;line-height:22px;color:#222;}
.footer-bottom .read-more-link{color:#015a98;font-size:14px;line-height:17px;font-weight:normal;}
.footer-bottom .read-more-link:hover{text-decoration:underline;}
.footer-read-more-link .icon-right-arrow{width:18px;height:18px;border-radius:100%;background:#015a98;color:#fff;}
.footer-bottom .footer-nav{font-size:14px;line-height:17px;}
.footer-bottom .footer-nav li,
.footer-contact-nav li{margin: 0 0 15px;}
.footer-bottom .footer-nav a{color:#222;padding:0 0 0 15px;position: relative;}
.footer-bottom .footer-nav a:before{width:7px;height:7px;content:"";border-radius:100%;position:absolute;left:0;top:7px;background:#aaaaaa;}
.footer-bottom .footer-nav a:hover{color:#015a98;}
.footer-bottom .footer-nav a:hover:before{background:#23679e;}
.time-schedule-holder{font-size:14px;line-height:17px;color:#222;}
.open-close.open-text{color:#4bac03;}
.open-close.close-text{color:#f00;}
.schedule-dropdown .dropdown-toggle,
.schedule-dropdown .show>.dropdown-toggle,
.schedule-dropdown .show>.dropdown-toggle:not(:disabled):not(.disabled).active,
.schedule-dropdown .show>.dropdown-toggle:not(:disabled):not(.disabled):active,
.schedule-dropdown .show>.dropdown-toggle:not(:disabled):not(.disabled).active:focus,
.schedule-dropdown .show>.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.schedule-dropdown .show>.dropdown-toggle:focus,
.schedule-dropdown .show>.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.schedule-dropdown .show>.dropdown-toggle:not(:disabled):not(.disabled):active,
.schedule-dropdown .show>.dropdown-toggle{padding:0;color:#222 !important;background:none !important;border:none;outline:none;box-shadow: none !important;}
.schedule-dropdown .dropdown-toggle svg{vertical-align: top;}
.schedule-dropdown .show>.dropdown-menu{width:218px;border:1px solid #d0d0d0;left:auto !important;right:0 !important;background:#fff;border-radius:0;padding:0;}
.schedule-dropdown .dropdown-toggle:after{display:none;}
.timing-list{font-size:13px;line-height:16px;color:#000000;}
.timing-list li{padding:7px 10px;}
.timing-list .timing-list-day{width:80px;}
.footer-contact-nav li svg{width:18px;fill:#222;height: auto;margin: 0 12px 0 0;}
.footer-contact-nav li a{color:#222;}
.footer-copyright-text{font-size:13px;line-height:16px;padding:15px 0;background:#c3c3c3;position:relative;}
.footer-copyright-text p{margin: 0;}
.footer-contact-nav li:hover a{color:#015a98;}
.footer-contact-nav li:hover svg{fill:#015a98;}
.footer-copyright-text .project-by{width:45px;position:absolute;top:0;right:0;}

/* Special Site Styles */
.special-site .footer-logo .site-basic-logo{display:none;}
.special-site .footer-logo .site-special-logo{display:block;}
.special-site .footer-tags-block h3,
.special-site .footer-bottom .footer-nav a:hover,
.special-site .footer-contact-nav li:hover a,
.special-site .footer-bottom .read-more-link{color:#750183;}
.special-site .footer-top:before,
.special-site .footer-read-more-link .icon-right-arrow,
.special-site .footer-bottom .footer-nav a:hover:before{background:#750183;}
.special-site .footer-contact-nav li:hover svg{fill:#750183;}
@media only screen and (max-width:1199px){
	.footer-bottom h3{font-size:17px;}
}
@media only screen and (max-width:991px){
    .footer-top{padding:50px 0 0;overflow: hidden;}
    .footer-bottom{padding:50px 0 30px;}
}
@media only screen and (max-width:767px){
    .tags-cloud li{font-size:12px;margin:0 5px 5px 0;}
    .tags-cloud li a{padding:5px 10px;}
    .footer-top{padding:30px 0 0;}
    .footer-bottom{padding:30px 0;}
}
@media only screen and (max-width:575px){
    .footer-copyright-text{font-size:12px;padding-right:43px;}
}