.fullpage-loader-holder{position: fixed;left:0;top:0;right:0;bottom: 0;z-index:10;background:rgba(255,255,255,0.9);}
.fullpage-loader {width: 112px;height: 112px; position: absolute;left:50%;top:50%;-o-transform: translate(-50%,-50%);-ms-transform: translate(-50%,-50%);-moz-transform: translate(-50%,-50%);-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);}
/* .fullpage-loader .box1,.fullpage-loader .box2,.fullpage-loader .box3 {background:#025a99;box-sizing: border-box;position: absolute;display: block;}
.fullpage-loader .box1 {width: 112px;height: 48px;margin-top: 64px;margin-left: 0px;-webkit-animation: anime1 4s 0s forwards ease-in-out infinite;animation: anime1 4s 0s forwards ease-in-out infinite;}
.fullpage-loader .box2 {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;-webkit-animation: anime2 4s 0s forwards ease-in-out infinite;animation: anime2 4s 0s forwards ease-in-out infinite;}
.fullpage-loader .box3 {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;-webkit-animation: anime3 4s 0s forwards ease-in-out infinite;animation: anime3 4s 0s forwards ease-in-out infinite;}
@-webkit-keyframes anime1 {
    0% {width: 112px;height: 48px;margin-top: 64px;margin-left: 0px;}
    12.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    25% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    37.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    50% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    62.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    75% {width: 48px;height: 112px;margin-top: 0px;margin-left: 0px;}
    87.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    100% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
}
@keyframes anime1 {
    0% {width: 112px;height: 48px;margin-top: 64px;margin-left: 0px;}
    12.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    25% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    37.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    50% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    62.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 0px;}
    75% {width: 48px;height: 112px;margin-top: 0px;margin-left: 0px;}
    87.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    100% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
}
@-webkit-keyframes anime2 {
    0% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    12.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    25% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    37.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    50% {width: 112px;height: 48px;margin-top: 0px;margin-left: 0px;}
    62.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    75% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    87.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    100% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
}
@keyframes anime2 {
    0% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    12.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    25% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    37.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 0px;}
    50% {width: 112px;height: 48px;margin-top: 0px;margin-left: 0px;}
    62.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    75% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    87.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    100% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
}
@-webkit-keyframes anime3 {
    0% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    12.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    25% {width: 48px;height: 112px;margin-top: 0px;margin-left: 64px;}
    37.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    50% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    62.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    75% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    87.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    100% {width: 112px;height: 48px;margin-top: 64px;margin-left: 0px;}
}
@keyframes anime3 {
    0% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    12.5% {width: 48px;height: 48px;margin-top: 0px;margin-left: 64px;}
    25% {width: 48px;height: 112px;margin-top: 0px;margin-left: 64px;}
    37.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    50% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    62.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    75% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    87.5% {width: 48px;height: 48px;margin-top: 64px;margin-left: 64px;}
    100% {width: 112px;height: 48px;margin-top: 64px;margin-left: 0px;}
}*/
.fullpage-loader .circle{
    width:20px;
    height:20px;
    position: absolute;
    border-radius: 50%;
    background-color: #015a98;
    left:15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.fullpage-loader .circle:nth-child(2){
    left:45%;
    animation-delay: .2s;
}
.fullpage-loader .circle:nth-child(3){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.fullpage-loader .shadow{
    width:20px;
    height:4px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.fullpage-loader .shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.fullpage-loader .shadow:nth-child(5){
    left:auto;
    right:15%;
    animation-delay: .3s;
}
.fullpage-loader span{
    position: absolute;
    top:75px;
    font-family: 'Lato';
    font-size: 20px;
    letter-spacing: 12px;
    color: #015a98;
    left:15%;
}