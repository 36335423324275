.language-selector{width:280px;position: fixed;top:122px;right:-280px;background:#015a98;color:#fff;z-index: 4;padding:0 10px;-o-transition: all ease-in-out 0.2s;-ms-transition: all ease-in-out 0.2s;-moz-transition: all ease-in-out 0.2s;-webkit-transition: all ease-in-out 0.2s;transition: all ease-in-out 0.2s;}
.language-selector.active{right:0;}
.language-selector .languages-holder{max-height:414px;overflow-y: auto;}
.language-selector .language-icon{position: absolute;top: 0;right:100%;background: #015a98;padding: 15px 15px;cursor:pointer;border-radius:4px 0 0 4px;z-index:2}
.language-selector .language-icon > i{margin:0 3px;}
.language-selector .language-label {display: block;position: relative;cursor: pointer;font-size: 15px;line-height:18px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;padding:16px 5px 12px 24px;text-transform: capitalize;margin-bottom:0;}
.language-selector .language-label input {position: absolute;opacity: 0;cursor: pointer;}
.language-selector .language-label .checkmark {position: absolute;top: 16px;left: 0;height: 16px;width: 16px;border-radius: 50%;background-color: #012a54;}
.language-selector .language-label input:checked ~ .checkmark {background-color: #012a54;}
.language-selector .language-label .checkmark:after {content: "";position: absolute;display: none;}
.language-selector .language-label input:checked ~ .checkmark:after {display: block;}
.language-selector .language-label .checkmark:after {top: 4px;left: 4px;width:8px;height:8px;border-radius: 50%;background: white;}
